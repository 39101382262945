import React from "react";
import theme from "theme";
import { Theme, Text, Icon, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { GoLocation, GoMail } from "react-icons/go";
import { TiPhone } from "react-icons/ti";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact-us"} />
		<Helmet>
			<title>
				Contact Us | Luminetic футбольне поле
			</title>
			<meta name={"description"} content={"Випробуйте найкращу оренду футбольного поля в місті!"} />
			<meta property={"og:title"} content={"Contact Us | Luminetic футбольне поле"} />
			<meta property={"og:description"} content={"Випробуйте найкращу оренду футбольного поля в місті!"} />
			<meta property={"og:image"} content={"https://lumineticswift.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://lumineticswift.com/img/14676665.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://lumineticswift.com/img/14676665.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://lumineticswift.com/img/14676665.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://lumineticswift.com/img/14676665.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://lumineticswift.com/img/14676665.png"} />
			<meta name={"msapplication-TileImage"} content={"https://lumineticswift.com/img/14676665.png"} />
			<meta name={"msapplication-TileColor"} content={"https://lumineticswift.com/img/14676665.png"} />
		</Helmet>
		<Components.Header />
		<Section
			text-align="center"
			background-color="--primary"
			color="--light"
			padding="80px 0"
			sm-padding="40px 0"
			background="--color-green"
		>
			<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0 0 0">
			Зв'яжіться з нами
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
			Ми тут, щоб допомогти вам з будь-якими питаннями або бронюванням. Зв'яжіться з нами за наступними контактами:
			</Text>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="go"
						icon={GoLocation}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
						align-self="center"
					/>
					<Text margin="0px 0px 18px 0px" color="--dark" font="--lead" lg-text-align="left">
					Місцезнаходження
					</Text>
					<Text margin="0" color="--darkL2" font="normal 500 24px/1.2 --fontFamily-sans" lg-text-align="left">
					Львівська Бічна 3, Тернопіль, 46002
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="ti"
						icon={TiPhone}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
						align-self="center"
					/>
					<Text margin="0px 0px 18px 0px" color="--dark" font="--lead" lg-text-align="left">
					Телефон
					</Text>
					<Text margin="0" color="--darkL2" font="--headline3" lg-text-align="left">
						096 971-47-41
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
					align-items="center"
				>
					<Icon
						category="go"
						icon={GoMail}
						margin="0px 0px 18px 0px"
						color="--dark"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--dark" font="--lead" lg-text-align="left">
						Email
					</Text>
					<Text margin="0" color="--darkL2" font="normal 500 24px/1.2 --fontFamily-sans" lg-text-align="left">
					info@lumineticswift.com
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 0 0" md-padding="36px 0 36px 0" quarkly-title="Images-19">
			<Override slot="SectionContent" width="100%" max-width="100%" />
			<Image src="https://lumineticswift.com/img/7.jpg" object-fit="cover" />
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});